body {
    font-family: 'Texta Narrow', sans-serif;
}

/* bootstrap overrides */

.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    padding-right: 6px;
    padding-left: 6px;
}

.row {
    margin-right: -6px;
    margin-left: -6px;
}

.bootstrap-select>.dropdown-toggle {
    max-width: 120px;
}

.larger-width .dropdown-toggle {
    max-width: 160px !important;
}

.smaller-width .dropdown-toggle {
    max-width: 50px !important;
}

.btn {
    font-size: 13px;
    padding: 4px 12px;
}

.btn-group-xs>.btn, .btn-xs {
    padding: 1px 5px !important;
    font-size: 11px !important;
}

.btn.btn-danger:hover {
    color: #fff !important;
}

.form-control {
    height: 30px;
    padding: 2px 12px;
    font-size: 13px;
}

.checkbox, .radio {
    margin-top: 0;
}

.panel-default {
    border: none;
}

.panel-heading {
    padding: 3px 15px;
    font-size: 15px;
}

hr {
    margin-top: 4px;
    margin-bottom: 4px;
}

.btn {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.btn:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.btn-primary {
    background-color: #AF1B33;
    color: #fff;
    border-color: #AF1B33;
}

.btn-primary:hover {
    background-color: #921a2f;
    color: #fff;
    border-color: #921a2f;
}

.btn-danger {
    color: #fff;
    background-color: #AF1B33;
    border-color: #AF1B33;
}

.btn-danger:hover {
    background-color: #a94442;
    border-color: #a94442;
}

body {
    background-color: #fff;
    font-size: 13px;
}

.panel {
    box-shadow: none;
    -webkit-box-shadow: none;
}
.nav.nav-tabs>li.active>a, .nav.nav-tabs>li.active>a:focus, .nav.nav-tabs>li.active>a:hover {
    background-color: #1f2322;
    color: #fff;
    border: none;
}

.nav-tabs>li>a {
    border: none;
}

.nav.nav-tabs>li>a:hover {
    background-color: #4B4C4D;
    color: #fff;
}

.nav.nav-tabs>li>a {
    color: #000;
    padding: 10px 15px;
}

.navbar {
    min-height: 0;
}

.navbar-default {
    background-color: #4B4C4D;
}

@media (min-width: 768px) {
    .sidebar {
        background-color: #4B4C4D;
        margin-top: 0;
        padding-top: 30px;
        width: 180px;
        padding-right: 8px;
        min-height: 100vh;
    }

    #page-wrapper {
        margin: 0 0 0 180px;
    }
}

.nav>li>a {
    color: #fff;
    padding: 8px 0 8px 12px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 11px;
}

.nav>li>a:focus {
    background-color: transparent;
}

.nav>li>a:hover, .sidebar ul li a.active {
    background-color: #1f2322;
}

.nav>li>a>img {
    max-width: 30px;
    margin-right: 6px;
}

.sidebar ul li {
    border-bottom: 2px solid #878787;
}

.page-header {
    color: #4B4C4D;
    text-transform: uppercase;
    letter-spacing: -1px;
    font-weight: bold;
    padding-bottom: 9px;
    margin: 20px 0 4px 0;
    border-bottom: none;
    font-size: 21px;
}

.breadcrumb {
    padding: 4px 30px;
    margin: -6px -30px;
    margin-bottom: 10px;
    background-color: #4b4c4d;
    color: #fff;
    text-transform: uppercase;
    border-radius: 0;
    font-size: 12px;
}

.breadcrumb>.active {
    color: #fff;
}

.breadcrumb>li>a {
    color: #fff;
    font-weight: bold;
}

.table-responsive {
    border: none !important;
}

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    border-top: none;
}

/* bootstrap-select (custom) override */
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: inherit !important;
}

.panel-default>.panel-heading {
    color: #fff;
    background-color: #138d8d;
    border-color: #ddd;
    font-size: 16px;
    font-weight: 500;
    border-bottom: none;
}


/* admin template overrides */

#page-wrapper {
    border-left: none !important;
    height: 100%;
    min-height: 100vh;
}



/* arrow label */
.arrow-label {
    background-color: #B84098;
    color: #fff;
    padding: 0 9px;
    text-transform: uppercase;
    font-size: 10px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    font-weight: 400;
    margin-right: 12px;
    padding-top: 3px;
    margin-bottom: 18px;
    float: left;
    /*display: inline;*/
    cursor: pointer;
    margin-left: 2px;
}

.arrow-label:after {
    float: right;
    content: '';
    width: 0;
    height: 0;
    margin-right: -20px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #B84098;
    margin-top: -2px;
}

.arrow-label.blue, .arrow-label.red, .arrow-label.purple, .arrow-label.darkblue, .arrow-label.orange, .arrow-label.arrow-label.grey, .arrow-label.darkgreen, .arrow-label.pink {
    margin-top: 4px;
    margin-right: 18px;
}

.arrow-label.blue {
    background-color: #6ECDDD;
}

.arrow-label.blue:after {
    border-left: 10px solid #6ECDDD;
}

.arrow-label.red {
    background-color: #CF2032;
}

.arrow-label.red:after {
    border-left: 10px solid #CF2032;
}

.arrow-label.purple {
    background-color: #B84098;
}

.arrow-label.purple:after {
    border-left: 10px solid #B84098;
}

.arrow-label.darkblue {
    background-color: #3959A8;
}

.arrow-label.darkblue:after {
    border-left: 10px solid #3959A8;
}

.arrow-label.orange {
    background-color: #F79736;
}

.arrow-label.orange:after {
    border-left: 10px solid #F79736;
}

.arrow-label.pink {
    background-color: #E91A62;
}

.arrow-label.pink:after {
    border-left: 10px solid #E91A62;
}

.arrow-label.darkgreen {
    background-color: #138d8d;
}

.arrow-label.darkgreen:after {
    border-left: 10px solid #138d8d;
}

.arrow-label.green {
    background-color: #4CB95B;
}

.arrow-label.green:after {
    border-left: 10px solid #4CB95B;
}

.arrow-label.grey {
    background-color: rgb(145, 146, 146);
}

.arrow-label.grey:after {
    border-left: 10px solid rgb(145, 146, 146);
}

.section-icon {
    width: 100%;
    max-width: 90px;
}


/* navbar mobile */
.sidebar .sidebar-nav.navbar-collapse {
    background-color: #4B4C4D;
}


/* custom table */
div.table-title {
    display: block;
    margin: auto;
    max-width: 600px;
    padding:5px;
    width: 100%;
}

.table-title h3 {
    color: #fafafa;
    font-size: 30px;
    font-weight: 400;
    font-style:normal;
    font-family: "Roboto", helvetica, arial, sans-serif;
    text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    text-transform:uppercase;
}


/*** Table Styles **/

.table-fill {
    background: white;
    border-radius:3px;
    border-collapse: collapse;
    height: 320px;
    margin: auto;
    max-width: 600px;
    padding:5px;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    animation: float 5s infinite;
}

th {
    color:#fff;;
    background: #282825;
    border-bottom:4px solid #9ea7af;
    border-right: 1px solid #343a45;
    font-size:14px; /* title font size */
    font-weight: bold;
    padding:24px;
    text-align:left;
    vertical-align:middle;
}

tr {
    border-top: 1px solid #C1C3D1;
    border-bottom: 1px solid #C1C3D1;
    color:#666B85;
    font-size:14px;
    font-weight:normal;
    text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
}

tr:first-child {
    border-top:none;
}

tr:last-child {
    border-bottom:none;
}

tr:nth-child(even) td {
    background:#EBEBEB;
}

tr:nth-child(even) td.yellow {
    background: #fffac2;
}

tr:last-child td:first-child {
    border-bottom-left-radius:3px;
}

tr:last-child td:last-child {
    border-bottom-right-radius:3px;
}

td {
    background:#FFFFFF;
    padding:20px;
    text-align:left;
    vertical-align:middle;
    font-weight:300;
    font-size:13px;
    border-right: 1px solid #C1C3D1;
}

td.yellow {
    background: #fffcdf;
}

th:nth-child(even) {
    background: #363a3d;
}

td:last-child {
    border-right: 0px;
}

th.text-left {
    text-align: left;
}

th.text-center {
    text-align: center;
}

th.text-right {
    text-align: right;
}

td.text-left {
    text-align: left;
}

td.text-center {
    text-align: center;
}

td.text-right {
    text-align: right;
}

/* */
td {
    font-size: 12px;
}

.table>thead>tr>th {
    border-bottom: 3px solid #9EA7AF !important;
}

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    padding: 8px 5px 8px 5px;
}

tr.blue th {
    background: #6ECDDD;
    border-right: 1px solid #6ECDDD;
}
tr.red th {
    background: #CF2032;
    border-right: 1px solid #CF2032;
}
tr.purple th {
    background: #B84098;
    border-right: 1px solid #B84098;
}
tr.darkblue th {
    background: #3959A8;
    border-right: 1px solid #3959A8;
}
tr.orange th {
    background: #F79736;
    border-right: 1px solid #F79736;
}
tr.pink th {
    background: #E91A62;
    border-right: 1px solid #E91A62;
}
tr.darkgreen th {
    background: #138D8D;
    border-right: 1px solid #138D8D;
}
tr.grey th {
    background: rgb(145, 146, 146);
    border-right: 1px solid rgb(145, 146, 146);    
}

/*
 *
 * FUNIL - GRAPHIC
 *
 */

* {
    box-sizing: border-box;
}

.line {
    position:relative;
    width:100%;
    height: 58px;
    margin-top: -20px;
}
.line:first-child {
    margin-top:0;
}
.stick {
    position:absolute;
    background-color: var(--color-1);
    width:calc(100% - var(--margin-circle, 0px));
    height:34px;
    top:14px;
    border-radius:6px 2px 2px 10px;
    margin-left:var(--margin-circle, 0);
    text-align: right;
    line-height: 35px;
    color: #fff;
    padding-right: 15px;
    font-size: 15px;
}
.stick input {
    text-align: left;
    padding: 0;
    border: none;
    margin-left: 10px;
    width: 52px;
    padding-left: 10px;
    height: 30px;
    background: transparent;
}
.circles {
    position:relative;
    width:var(--width-circle, 200px);
    margin-left:var(--margin-circle, 0);
}
.circle-0 {
    position:absolute;
    width:100%;
    height:34px;
    background-color: var(--color-1);
    border-radius:50%;
    top:26px;
}
.circle-1 {
    position:absolute;
    width:100%;
    height:34px;
    background-color: var(--color-2);
    border-radius:50%;
}
.circle-2 {
    position:absolute;
    width:50%;
    height:20px;
    background-color: var(--color-3);
    border-radius:50%;
    top:8px;
    left:0;
    margin-left:25%;
}

.line-1 {
    --color-1: #D1293D;
    --color-2: #EE4359;
    --color-3: #AD1D2F;
    z-index:15;
}
.line-2 {
    --width-circle: 180px;
    --margin-circle: 10px;
    --color-1: #F79635;
    --color-2: #F9AD67;
    --color-3: #CB762E;
    z-index:14;
}
.line-3 {
    --width-circle: 170px;
    --margin-circle: 15px;
    --color-1: #FCBC32;
    --color-2: #FDCB6A;
    --color-3: #E8AF46;
    z-index:13;
}
.line-4 {
    --width-circle: 160px;
    --margin-circle: 20px;
    --color-1: #4BB85A;
    --color-2: #74C26B;
    --color-3: #25A248;
    z-index:12;
}
.line-5 {
    --width-circle: 150px;
    --margin-circle: 25px;
    --color-1: #30BDB0;
    --color-2: #61C5BE;
    --color-3: #1F9888;
    z-index:11;
}

.line-6 {
    --width-circle: 140px;
    --margin-circle: 30px;
    --color-1: #2988c9;
    --color-2: #47b3e6;
    --color-3: #1d719b;
    z-index:10;
}

.line-7 {
    --width-circle: 130px;
    --margin-circle: 35px;
    --color-1: #3558a7;
    --color-2: #3d64af;
    --color-3: #1e4488;
    z-index:9;
}

.line-8 {
    --width-circle: 120px;
    --margin-circle: 40px;
    --color-1: #118d8d;
    --color-2: #21cccc;
    --color-3: #027171;
    z-index:8;
}

.line-9 {
    --width-circle: 110px;
    --margin-circle: 45px;
    --color-1: #6ecddd;
    --color-2: #adf5fa;
    --color-3: #4bc5d9;
    z-index:7;
}

.line-10 {
    --width-circle: 110px;
    --margin-circle: 45px;
    --color-1: #f6d454;
    --color-2: #fffab2;
    --color-3: #f6dd62;
    z-index:6;
}

.line-11 {
    --width-circle: 110px;
    --margin-circle: 45px;
    --color-1: #f16060;
    --color-2: #fe9c9c;
    --color-3: #f03b3b;
    z-index:5;
}

.line-12 {
    --width-circle: 110px;
    --margin-circle: 45px;
    --color-1: #e91a62;
    --color-2: #e91a62;
    --color-3: #c10e50;
    z-index:4;
}

.line-13 {
    --width-circle: 110px;
    --margin-circle: 45px;
    --color-1: #ae4a9c;
    --color-2: #e57fd9;
    --color-3: #9c2585;
    z-index:3;
}

.line-14 {
    --width-circle: 110px;
    --margin-circle: 45px;
    --color-1: #c02791;
    --color-2: #ef48d0;
    --color-3: #b40b76;
    z-index:2;
}

.line-15 {
    --width-circle: 110px;
    --margin-circle: 45px;
    --color-1: #142125;
    --color-2: #5f797f;
    --color-3: #35464a;
    z-index:1;
}


/*
*
* BEAUTIFUL CHECK BOX
*
*/

.checkbox {
    padding-left: 20px;
}
.checkbox label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;
    font-size: 11px;
}
.checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.checkbox label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 11px;
    color: #555555;
}
.checkbox input[type="checkbox"],
.checkbox input[type="radio"] {
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}
.checkbox input[type="checkbox"]:focus + label::before,
.checkbox input[type="radio"]:focus + label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}
.checkbox input[type="checkbox"]:checked + label::after,
.checkbox input[type="radio"]:checked + label::after {
    font-family: "FontAwesome";
    content: "\f00c";
}
.checkbox input[type="checkbox"]:indeterminate + label::after,
.checkbox input[type="radio"]:indeterminate + label::after {
    display: block;
    content: "";
    width: 10px;
    height: 3px;
    background-color: #555555;
    border-radius: 2px;
    margin-left: -16.5px;
    margin-top: 7px;
}
.checkbox input[type="checkbox"]:disabled,
.checkbox input[type="radio"]:disabled {
    cursor: not-allowed;
}
.checkbox input[type="checkbox"]:disabled + label,
.checkbox input[type="radio"]:disabled + label {
    opacity: 0.65;
}
.checkbox input[type="checkbox"]:disabled + label::before,
.checkbox input[type="radio"]:disabled + label::before {
    background-color: #eeeeee;
    cursor: not-allowed;
}
.checkbox.checkbox-circle label::before {
    border-radius: 50%;
}
.checkbox.checkbox-inline {
    margin-top: 0;
}

.checkbox-primary input[type="checkbox"]:checked + label::before,
.checkbox-primary input[type="radio"]:checked + label::before {
    background-color: #337ab7;
    border-color: #337ab7;
}
.checkbox-primary input[type="checkbox"]:checked + label::after,
.checkbox-primary input[type="radio"]:checked + label::after {
    color: #fff;
}

.checkbox-danger input[type="checkbox"]:checked + label::before,
.checkbox-danger input[type="radio"]:checked + label::before {
    background-color: #d9534f;
    border-color: #d9534f;
}
.checkbox-danger input[type="checkbox"]:checked + label::after,
.checkbox-danger input[type="radio"]:checked + label::after {
    color: #fff;
}

.checkbox-info input[type="checkbox"]:checked + label::before,
.checkbox-info input[type="radio"]:checked + label::before {
    background-color: #5bc0de;
    border-color: #5bc0de;
}
.checkbox-info input[type="checkbox"]:checked + label::after,
.checkbox-info input[type="radio"]:checked + label::after {
    color: #fff;
}

.checkbox-warning input[type="checkbox"]:checked + label::before,
.checkbox-warning input[type="radio"]:checked + label::before {
    background-color: #f0ad4e;
    border-color: #f0ad4e;
}
.checkbox-warning input[type="checkbox"]:checked + label::after,
.checkbox-warning input[type="radio"]:checked + label::after {
    color: #fff;
}

.checkbox-success input[type="checkbox"]:checked + label::before,
.checkbox-success input[type="radio"]:checked + label::before {
    background-color: #5cb85c;
    border-color: #5cb85c;
}
.checkbox-success input[type="checkbox"]:checked + label::after,
.checkbox-success input[type="radio"]:checked + label::after {
    color: #fff;
}

.checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.checkbox-primary input[type="radio"]:indeterminate + label::before {
    background-color: #337ab7;
    border-color: #337ab7;
}

.checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.checkbox-primary input[type="radio"]:indeterminate + label::after {
    background-color: #fff;
}

.checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.checkbox-danger input[type="radio"]:indeterminate + label::before {
    background-color: #d9534f;
    border-color: #d9534f;
}

.checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.checkbox-danger input[type="radio"]:indeterminate + label::after {
    background-color: #fff;
}

.checkbox-info input[type="checkbox"]:indeterminate + label::before,
.checkbox-info input[type="radio"]:indeterminate + label::before {
    background-color: #5bc0de;
    border-color: #5bc0de;
}

.checkbox-info input[type="checkbox"]:indeterminate + label::after,
.checkbox-info input[type="radio"]:indeterminate + label::after {
    background-color: #fff;
}

.checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.checkbox-warning input[type="radio"]:indeterminate + label::before {
    background-color: #f0ad4e;
    border-color: #f0ad4e;
}

.checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.checkbox-warning input[type="radio"]:indeterminate + label::after {
    background-color: #fff;
}

.checkbox-success input[type="checkbox"]:indeterminate + label::before,
.checkbox-success input[type="radio"]:indeterminate + label::before {
    background-color: #5cb85c;
    border-color: #5cb85c;
}

.checkbox-success input[type="checkbox"]:indeterminate + label::after,
.checkbox-success input[type="radio"]:indeterminate + label::after {
    background-color: #fff;
}

.radio {
    padding-left: 20px;
}
.radio label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;
}
.radio label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #cccccc;
    border-radius: 50%;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out;
    transition: border 0.15s ease-in-out;
}
.radio label::after {
    display: inline-block;
    position: absolute;
    content: " ";
    width: 11px;
    height: 11px;
    left: 3px;
    top: 3px;
    margin-left: -20px;
    border-radius: 50%;
    background-color: #555555;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}
.radio input[type="radio"] {
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}
.radio input[type="radio"]:focus + label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}
.radio input[type="radio"]:checked + label::after {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
}
.radio input[type="radio"]:disabled {
    cursor: not-allowed;
}
.radio input[type="radio"]:disabled + label {
    opacity: 0.65;
}
.radio input[type="radio"]:disabled + label::before {
    cursor: not-allowed;
}
.radio.radio-inline {
    margin-top: 0;
}

.radio-primary input[type="radio"] + label::after {
    background-color: #337ab7;
}
.radio-primary input[type="radio"]:checked + label::before {
    border-color: #337ab7;
}
.radio-primary input[type="radio"]:checked + label::after {
    background-color: #337ab7;
}

.radio-danger input[type="radio"] + label::after {
    background-color: #d9534f;
}
.radio-danger input[type="radio"]:checked + label::before {
    border-color: #d9534f;
}
.radio-danger input[type="radio"]:checked + label::after {
    background-color: #d9534f;
}

.radio-info input[type="radio"] + label::after {
    background-color: #5bc0de;
}
.radio-info input[type="radio"]:checked + label::before {
    border-color: #5bc0de;
}
.radio-info input[type="radio"]:checked + label::after {
    background-color: #5bc0de;
}

.radio-warning input[type="radio"] + label::after {
    background-color: #f0ad4e;
}
.radio-warning input[type="radio"]:checked + label::before {
    border-color: #f0ad4e;
}
.radio-warning input[type="radio"]:checked + label::after {
    background-color: #f0ad4e;
}

.radio-success input[type="radio"] + label::after {
    background-color: #5cb85c;
}
.radio-success input[type="radio"]:checked + label::before {
    border-color: #5cb85c;
}
.radio-success input[type="radio"]:checked + label::after {
    background-color: #5cb85c;
}

input[type="checkbox"].styled:checked + label:after,
input[type="radio"].styled:checked + label:after {
    font-family: 'FontAwesome';
    content: "\f00c";
}
input[type="checkbox"] .styled:checked + label::before,
input[type="radio"] .styled:checked + label::before {
    color: #fff;
}
input[type="checkbox"] .styled:checked + label::after,
input[type="radio"] .styled:checked + label::after {
    color: #fff;
}

/*
*
*
* END BEAUTIFUL CHECKBOX
*
*
*/

/* column */
.column_3 {
    -webkit-column-count: 4; /* Chrome, Safari, Opera */
    -moz-column-count: 4; /* Firefox */
    column-count: 4;
    margin-top: 8px;
}

.panel-primary {
    border-color: #148d8e;
    background-color: #148d8e;
    color: #fff;
    font-size: 15px;
}


#footer {
    bottom: 0;
}

#footer img {
    margin-top: 40px;
    width: auto;
    height: 24px;
}


/* placar custom table */
.table.placar>tbody>tr>td, .table.placar>tbody>tr>th, .table.placar>tfoot>tr>td,
.table.placar>tfoot>tr>th, .table.placar>thead>tr>td, .table.placar>thead>tr>th {
    padding: 0 0;
    font-size: 11px;
    text-align: center;
}

.tooltip.top .tooltip-inner {
    background-color:#444;
}
.tooltip.top .tooltip-arrow {
    border-top-color: #444;
}
.tooltip.right .tooltip-inner {
    background-color:#444;
}
.tooltip.right .tooltip-arrow {
    border-right-color: #444;
}
.tooltip.bottom .tooltip-inner {
    background-color:#444;
}
.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #444;
}
.tooltip.left .tooltip-inner {
    background-color:#444;
}
.tooltip.left .tooltip-arrow {
    border-left-color: #444;
}


/* notes */
#notes ul, #notes li{
    list-style:none;
}
#notes ul{
    padding:0;
}
#notes ul li {
    text-decoration:none;
    color:#000;
    background:#ffc;
    display:block;
    width:100%;
}

#notes ul li div#notes-title {
    border-bottom: 6px solid #ccc;
    padding: 6px;
}

#notes ul li .notes-content-title {
    padding: 2px 6px;
    margin-top: 4px;
}

#notes ul li .notes-content {
    padding:0;
}

#notes textarea {
    background-color: transparent;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}

#notes button {
    background-color: transparent;
    border: none;
    width: 100%;
    max-width: 120px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: transparent !important;
    outline-offset: 0;
}

#notes .dropdown-menu {
    background-color: #ffc;
    font-size: 12px;
    max-width: 150px;
}


.ranking th {
    border-bottom: none;
}

/* pretty data-toogle input */
button[data-toggle=dropdown] {
    /*display: none;*/
    max-width: 200px;
    overflow: auto;
}

.nice-select {
    font-size: 12px !important;
    height: 26px !important;
    line-height: 24px !important;
    clear: none;
    max-width: 200px;
    /*display: none;*/
    /*overflow: hidden;*/
}

.nice-select.hidden {
    /*display: none;*/
    max-width: 300px;
}

.nice-select.open {
    /*display: inherit;*/
    max-width: 200px;
    overflow: inherit;
}

.nice-select.open, .nice-select:active, .nice-select:focus {
    border-color: transparent;
    /*width: 0 !important;*/
    /*height: 0 !important;*/
    margin-left: 6px;
    max-width: 200px;
    max-height: 50px;
}

.nice-select.open:after {
    /*display: none;*/
    max-width: 200px;
    max-height: 50px;
    overflow: auto;
}

.nice-select.open .list {
    z-index: 42;
    max-width: 300px;
    max-height: 200px;
    overflow: auto;
}

/* colored table */

.table.table-colored thead th {
    border-bottom: 0 !important;
    background: orange;
    border-radius: 8px;
    border-right: 6px solid #c9c9cb;
    border-left: 6px solid #c9c9cb;
}

.table.table-colored tbody td {
    border-right: 6px solid #c9c9cb;
    border-left: 6px solid #c9c9cb;
    border-top: 3px solid #c9c9cb;
    border-radius: 8px;
}

.table.table-colored td.title {
    background: transparent;
    color: #000;
    font-weight: 400;
}

.table.table-colored {
    width: 96%;
    margin-left: 2%;
}

.table-wrapper {
    background-color: #c9c9cb;
    padding-bottom: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
}

.table-colored tr:nth-child(even) td {
    background: #fff;
}

.table-title {
    background-color: #FFA500;
    width: 100%;
    height: 40px;
    color: #fff;
    display: block;
}

.table-title span {
    margin-left: 20px;
    line-height: 38px;
    font-size: 18px;
    font-weight: 100;
}

/* table-colored extra colors */

.table.table-colored.blue thead th {
    background: #5bc0de;
}

.table-title.blue {
    background-color: #5bc0de;
}

.table.table-colored.red thead th {
    background: #CF2032;
}

.table-title.red {
    background-color: #CF2032;
}

.table.table-colored.purple thead th {
    background: #B84098;
}

.table-title.purple {
    background-color: #B84098;
}

.table.table-colored.pink thead th {
    background: #E91A62;
}

.table-title.pink {
    background-color: #E91A62;
}

.table.table-colored.darkblue thead th {
    background: #3959A8;
}

.table-title.darkblue {
    background-color: #3959A8;
}